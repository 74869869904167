<template>
    <div class="step-component row">
        <div class="col-sm-11">
            <div class="row mb-3">
                <div class="col-sm-2 text-right textCost">其它费用</div>
                <div class="col-sm-10 row">
                    <div
                        class="mb-3"
                        v-for="(other, index) in otherCharges"
                        :key="index"
                    >
                        <div class="row mb-1">
                            <div style="margin-top: 0; display: inline-block; margin-right: 20px;">
                                <input
                                    type="radio"
                                    v-model="other.multiplePause"
                                    id="once-pay"
                                    :value="false"
                                />
                                <label for="once-pay" style="color: #000; margin-left: 10px;"
                                    >一次性付清【例：钥匙押金等】</label
                                >
                            </div>
                            <div style="margin-top: 0; display: inline-block;">
                                <input
                                    type="radio"
                                    v-model="other.multiplePause"
                                    id="multiple-pay"
                                    :value="true"
                                />
                                <label for="multiple-pay" style="color: #000; margin-left: 10px;margin-right: 15px;"
                                    >分期支付</label
                                >
                                <div
                                    class="glyphicon glyphicon-remove-circle1"
                                    @click="changeOtherChargesCount(index)"
                                ></div>
                            </div>
                        </div>
                        <div class="mb-1" style="color: #000">
                            费用名称
                            <input
                                type="text"
                                class="custom-input"
                                v-model="other.name"
                                placeholder="限10个字"
                            />
                        </div>
                        <PayPhaseComponent
                            :data="other.installments"
                            :allow-multiple-phase="other.multiplePause"
                            @change-pay-pause="
                                (data) => {
                                    changeChargeInstallments(index, data);
                                }
                            "
                        ></PayPhaseComponent>
                    </div>
                    <button
                        class="btn btn-primary" style="margin-left: 10px;"
                        @click="changeOtherChargesCount()"
                    >
                        <i class="glyphicon glyphicon-plus"></i>
                        添加其它费用
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script scoped>
import PayPhaseComponent from "@/components/PayPhaseComponent.vue";

export default {
    name: "ContractThirdStep",
    components: {
        PayPhaseComponent,
    },
    data() {
        return {
            otherCharges: [
               /* {
                    name: "",
                    multiplePause: false,
                    installments: [
                        {
                            deduction: false,
                            paymentDate: "",
                            amount: "",
                            deductionAmount: "",
                            comment: "",
                        },
                    ],
                },*/
            ],
        };
    },
    methods: {
        /**
         * 添加或删除其他费用类型
         * @param {Number | Null} index 要删除的下标  为null时说明是添加费用类型
         *  */
        changeOtherChargesCount(index) {
            if (index != null) {
                this.otherCharges.splice(index, 1);
            } else {
                const defaultCharges = {
                    name: "",
                    multiplePause: false,
                    installments: [
                        {
                            deduction: false,
                            paymentDate: "",
                            amount: "",
                            deductionAmount: "",
                            comment: "",
                        },
                    ],
                };
                this.otherCharges.push(defaultCharges);
            }
        },
        /**
         * 修改费用的付费方式
         * @param {Number} index 要修改的费用下标
         * @param {Number} data 费用的支付方式
         *  */
        changeChargeInstallments(index, data) {
            this.$set(this.otherCharges[index], "installments", data);
        },
        getData() {
            const otherCharges = [];
            this.otherCharges.forEach((charge, index) => {
                const { installments, name } = charge;
                otherCharges.push({
                    installments,
                    name,
                });
            });

            return { otherCharges };
        },
    },
};
</script>

<style scoped>
.col-sm-6 label {
    margin-left: 10px;
}

.custom-input {
    width: 200px;
    /* color: #999999; */
}
.backgauge {
    margin: 0 10px 0 0;
}
.mb-1 {
    margin: 0px 0 0 10px;
}

.glyphicon-remove-circle1 {
    width: 26px;
    height: 26px;
    background: url(../assets/icon4.png);
    vertical-align: middle;
    margin-bottom: 0.5rem;
}
.label {
    color: #000;
}
.textCost{
    line-height: 50px;
}
</style>
